* {
  font-family: 'Sora', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.add-color-bt {
  float: right;
  margin-top: 2px;
}
.add-address-bt {
  width: 100%;
}
.admin-product-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1rem;
  margin: 1rem;
  width: 100%;
}
.admin-product-edit {
  width: 100%;
}
.cart-button {
  position: absolute;
}
.cart-icon {
  color: white;
}
.cart-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1rem;
  margin: 1rem;
  width: 100%;
}
.checkout-message {
  justify-content: 'center';
}
.cover-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.delete-color {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  background-color: crimson;
}
.line {
  width: 100%;
  border-style: solid;
  border-color: black;
}
/* img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  padding: 0.75rem;
} */
.addproduct-img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  padding: 0.75rem;
}
.cover-list img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.delete-photo button {
  border: 1rem;
  border-radius: 1rem;
  border-color: orange;
  background-color: orange;
  /* color: orange; */
  width: 100px;
  height: 40px;
}
.delete-photo button:disabled {
  border: 1rem;
  border-radius: 1rem;
  border-color: none;
  background-color: #ccc;
  /* color: orange; */
  cursor: none;
  width: 100px;
  height: 40px;
}
.delete-photo button:hover {
  cursor: pointer;
}

.information-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1rem;
  margin: 1rem;
  width: 100%;
}
.imgsPreview .imageHolder {
  width: 150px;
  height: 150px;
  position: relative;
  margin: 7px 8px;
  display: inline-block;
}
.imgsPreview .imageHolder img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.imgsPreview .imageHolder .delete {
  position: absolute;
  top: 6px;
  right: 15px;
  width: 32px;
  height: 32px;
  /* opacity: 0.5; */
  border-radius: 50%;
}
.imgsPreview .imageHolder .delete:hover {
  cursor: pointer;
}
.imgsPreview .imageHolder .delete .icon {
  width: 66%;
  height: 66%;
  display: block;
  margin: 4px auto;
}
.img-wrap {
  position: relative;
}
.edit-photo-container {
  /* border: 1px solid #ccc;
  border-radius: 5px; */
  /* padding: 1rem;
  margin: 1rem; */
  width: 100%;
}

.edit-photo-form {
  height: 200px;
  width: 200px;
  /* border-style: dotted; */
  /* border-color: black; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 80%;

  margin: 30px;
  justify-content: center;
}

.edit-photo-form img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.product-form {
  width: 100%;
}
.product-select {
  display: flex;
  flex-direction: column;
  margin: 2px;
}

.result {
  /* min-height: 100%; */
  max-height: auto;
  width: 100%;
  border-color: antiquewhite;
  /* background-color: #272c34; */
  /* margin-top:1rem ; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
}
.result .delete {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.table-head {
  justify-content: 'center';
}
.table-sales {
  min-width: 320px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 1rem;
  padding: 1rem;
  width: 100%;
}

.upload {
  width: 100%;
  height: 500px;
  border-style: dotted;
  border-color: black;
  overflow: scroll;
}
.upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  margin: 0 auto;
}
.upload-cover-button {
  height: 200px;
  width: 200px;
  border: none;
  align-items: center;
  display: flex;
  justify-content: center;
}
.upload-cover-form {
  height: 150px;
  width: 150px;
  border-style: dotted;
  border-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;

  margin: 15px;
  justify-content: center;
}
.upload-cover-form img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.upload-photo-button {
  margin: 10px;
  width: 100%;
  border: none;
  align-items: center;
  display: flex;
  justify-content: center;
}
.sarid-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 5px;
}

.table-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin: auto;
  margin-top: 5px;
}

.sarid-button {
  width: 100%;
}
.line-button {
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 1rem;
  background-color: #06c755;
}

.line-button-contact {
  width: 250px;
  height: 50px;
  border: none;
  border-radius: 1rem;
  background-color: #06c755;
}
.line-button-contact:hover {
  cursor: pointer;
}

.line-btn span.icon {
  background: url(./assets/btn_base.png) no-repeat;
  background-size: cover;
  position: absolute;
  margin-left: 30px;
  margin-top: 5px;
  width: 40px;
  height: 40px;
}
.line-button:hover {
  cursor: pointer;
}

.line-qr {
  width: 200px;
  height: 200px;
}
.line-qr img {
  width: 100%;
  height: 100%;
}
.space-bt {
  /* padding: 1rem; */
  margin-bottom: 1rem;
}
.space-bt-left {
  margin-left: 1rem;
}

.main-content {
  margin-top: 2rem;
}

.product {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1rem 0;
  margin: 1rem 0;
  width: 100%;
}
.product-card-container {
  justify-content: center;
  align-items: center;
}
.product-card .imageHolder {
  width: 280px;
  height: 350px;
  position: relative;
  margin: 7px 8px;
  display: inline-block;
}
.product-card .imageHolder img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.product-card .imageHolder .product-card-name {
  position: absolute;
  bottom: 60px;
  left: 15px;
  width: 100%;
  /* background-color: white; */
}
.product-card .imageHolder .product-card-bt {
  position: absolute;
  bottom: 20px;
  left: 15px;
  width: 100px;
}
.product-card .imageHolder .product-card-bt:hover {
  cursor: pointer;
}
.product-detail {
  padding: 0 2rem;
}

.product-price {
  font-weight: bold;
  font-size: 140%;
}

.slide-container {
  width: 100%;
  margin: auto;
}
.sarid-head {
  width: 100%;
  background-color: 'black';
}

h3 {
  text-align: center;
}

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  height: 640px;
  background-repeat: no-repeat;
  background-position: center center;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 100%;
  overflow: hidden;
}

.each-fade .image-container img {
  width: 100%;
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}

.pass-wrapper {
  position: relative;
  display: flex;
  margin-bottom: 14px;
}

i {
  position: absolute;
  top: 30%;
  right: 5%;
}
i:hover {
  color: #50bd9e;
  cursor: pointer;
}

test {
  color: #2c3042;
  cursor: pointer;
}
.product-card {
  position: relative;
  display: inline-block;
}
.product-card .product-card-name {
  position: absolute;
  margin-top: 5px;
  right: 15px;
  background-color: darkblue;
  width: 50px;
}
